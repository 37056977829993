import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VRow,
        {
          class: _vm.isMobile() ? "mx-0" : "mx-10",
          staticStyle: { "text-align": "left" },
        },
        [
          _c(
            VCol,
            {
              staticClass: "py-0",
              attrs: { cols: "12", xl: "12", lg: "12", md: "12", sm: "12" },
            },
            [
              _c(VCheckbox, {
                staticClass: "field",
                attrs: {
                  color: "primary",
                  outlined: "",
                  dense: "",
                  "data-test": "complete_profile:bank_foreign",
                },
                on: {
                  click: function ($event) {
                    return _vm.$emit("changeForegin")
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function () {
                      return [
                        _c("span", { staticStyle: { color: "var(--dark)" } }, [
                          _vm._v(_vm._s(_vm.$t("bank_is_foreign"))),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.BankAccount.IsForeign,
                  callback: function ($$v) {
                    _vm.$set(_vm.BankAccount, "IsForeign", $$v)
                  },
                  expression: "BankAccount.IsForeign",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.BankAccount.IsForeign
        ? _c(
            VRow,
            {
              class: _vm.isMobile() ? "mx-0" : "mx-10",
              staticStyle: { "text-align": "left" },
            },
            [
              _c(
                VCol,
                {
                  staticClass: "py-0",
                  attrs: { cols: "12", xl: "12", lg: "12", md: "12", sm: "12" },
                },
                [
                  _c("p", { staticClass: "ml-2 mb-2 dark-color" }, [
                    _vm._v(" " + _vm._s(_vm.$t("bank")) + " "),
                  ]),
                  _c(VAutocomplete, {
                    staticClass: "field my-input-style",
                    attrs: {
                      placeholder: _vm.$t("insert_bank_code"),
                      color: "primary",
                      "no-data-text": _vm.$t("not_found"),
                      outlined: "",
                      dense: "",
                      items: _vm.banks,
                      filter: _vm.customFilter,
                      "item-value": "COMPE",
                      rules: [_vm.required],
                      "data-test": "complete_profile:bank",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "selection",
                          fn: function (data) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    data.item.COMPE + " - " + data.item.LongName
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "item",
                          fn: function (data) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    data.item.COMPE + " - " + data.item.LongName
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      975023836
                    ),
                    model: {
                      value: _vm.BankAccount.Bank,
                      callback: function ($$v) {
                        _vm.$set(_vm.BankAccount, "Bank", $$v)
                      },
                      expression: "BankAccount.Bank",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCol,
                {
                  staticClass: "py-0",
                  attrs: { cols: "12", xl: "6", lg: "6", md: "6", sm: "6" },
                },
                [
                  _c("p", { staticClass: "ml-2 mb-2 dark-color" }, [
                    _vm._v(" " + _vm._s(_vm.$t("agency")) + " "),
                  ]),
                  _c(VTextField, {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: _vm.agency_mask,
                        expression: "agency_mask",
                      },
                    ],
                    staticClass: "field my-input-style",
                    attrs: {
                      placeholder: _vm.$t("insert_bank_agency"),
                      color: "primary",
                      outlined: "",
                      onkeyup: _vm.checkTheMaskType(),
                      dense: "",
                      rules: [
                        function (v) {
                          return (
                            (!!v && v.length >= 3 && v.length <= 6) ||
                            _vm.required()
                          )
                        },
                      ],
                      "data-test": "complete_profile:bank_agency",
                    },
                    model: {
                      value: _vm.BankAccount.Agency,
                      callback: function ($$v) {
                        _vm.$set(_vm.BankAccount, "Agency", $$v)
                      },
                      expression: "BankAccount.Agency",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCol,
                {
                  staticClass: "py-0",
                  attrs: { cols: "12", xl: "6", lg: "6", md: "6", sm: "6" },
                },
                [
                  _c("p", { staticClass: "ml-2 mb-2 dark-color" }, [
                    _vm._v(" " + _vm._s(_vm.$t("account")) + " "),
                  ]),
                  _c(VTextField, {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: _vm.account_mask,
                        expression: "account_mask",
                      },
                    ],
                    staticClass: "field my-input-style",
                    attrs: {
                      placeholder: _vm.$t("insert_bank_account"),
                      color: "primary",
                      onkeyup: _vm.checkTheMaskType(),
                      outlined: "",
                      dense: "",
                      rules: [
                        function (v) {
                          return (
                            (!!v && v.length >= 4 && v.length <= 14) ||
                            _vm.required()
                          )
                        },
                      ],
                      "data-test": "complete_profile:bank_account",
                    },
                    model: {
                      value: _vm.BankAccount.Account,
                      callback: function ($$v) {
                        _vm.$set(_vm.BankAccount, "Account", $$v)
                      },
                      expression: "BankAccount.Account",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            VRow,
            {
              class: _vm.isMobile() ? "mx-0" : "mx-10",
              staticStyle: { "text-align": "left" },
            },
            [
              _c(
                VCol,
                {
                  staticClass: "py-0",
                  attrs: { cols: "12", xl: "12", lg: "12", md: "12", sm: "12" },
                },
                [
                  _c("p", { staticClass: "ml-2 mb-2 my-input-style" }, [
                    _vm._v(" " + _vm._s(_vm.$t("bank")) + " "),
                  ]),
                  _c(VTextField, {
                    staticClass: "field",
                    attrs: {
                      color: "primary",
                      outlined: "",
                      dense: "",
                      rules: [_vm.required],
                      "data-test": "complete_profile:bank_account",
                    },
                    model: {
                      value: _vm.BankAccount.Bank,
                      callback: function ($$v) {
                        _vm.$set(_vm.BankAccount, "Bank", $$v)
                      },
                      expression: "BankAccount.Bank",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCol,
                {
                  staticClass: "py-0",
                  attrs: { cols: "12", xl: "6", lg: "6", md: "6", sm: "6" },
                },
                [
                  _c("p", { staticClass: "ml-2 mb-2 my-input-style" }, [
                    _vm._v(" " + _vm._s(_vm.$t("account")) + " "),
                  ]),
                  _c(VTextField, {
                    staticClass: "field",
                    attrs: {
                      color: "primary",
                      outlined: "",
                      dense: "",
                      rules: [_vm.required],
                      "data-test": "complete_profile:bank_account",
                    },
                    model: {
                      value: _vm.BankAccount.Account,
                      callback: function ($$v) {
                        _vm.$set(_vm.BankAccount, "Account", $$v)
                      },
                      expression: "BankAccount.Account",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCol,
                {
                  staticClass: "py-0",
                  attrs: { cols: "12", xl: "6", lg: "6", md: "6", sm: "6" },
                },
                [
                  _c("p", { staticClass: "ml-2 mb-2 my-input-style" }, [
                    _vm._v(" " + _vm._s(_vm.$t("beneficiary")) + " "),
                  ]),
                  _c(VTextField, {
                    staticClass: "field",
                    attrs: {
                      color: "primary",
                      outlined: "",
                      dense: "",
                      rules: [_vm.required],
                      "data-test": "complete_profile:beneficiary",
                    },
                    model: {
                      value: _vm.BankAccount.Beneficiary,
                      callback: function ($$v) {
                        _vm.$set(_vm.BankAccount, "Beneficiary", $$v)
                      },
                      expression: "BankAccount.Beneficiary",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCol,
                {
                  staticClass: "py-0",
                  attrs: { cols: "12", xl: "12", lg: "12", md: "12", sm: "12" },
                },
                [
                  _c("p", { staticClass: "ml-2 mb-2 my-input-style" }, [
                    _vm._v(" " + _vm._s(_vm.$t("address")) + " "),
                  ]),
                  _c(VTextField, {
                    staticClass: "field",
                    attrs: {
                      color: "primary",
                      outlined: "",
                      dense: "",
                      rules: [_vm.required],
                      "data-test": "complete_profile:bank_address",
                    },
                    model: {
                      value: _vm.BankAccount.Address,
                      callback: function ($$v) {
                        _vm.$set(_vm.BankAccount, "Address", $$v)
                      },
                      expression: "BankAccount.Address",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCol,
                {
                  staticClass: "py-0",
                  attrs: { cols: "12", xl: "6", lg: "6", md: "6", sm: "6" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "swift-tooltip-box" },
                    [
                      _c("p", { staticClass: "ml-2 mb-0" }, [
                        _vm._v(" " + _vm._s(_vm.$t("swift")) + " "),
                      ]),
                      _vm.gs.isMobile()
                        ? _c(
                            VTooltip,
                            {
                              attrs: { "max-width": "300", left: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "div",
                                          _vm._g(
                                            _vm._b({}, "div", attrs, false),
                                            on
                                          ),
                                          [
                                            _c("font-awesome-icon", {
                                              staticClass: "swift-tooltip-icon",
                                              attrs: {
                                                icon: "fa-solid fa-circle-info",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.swiftTooltip =
                                                    !_vm.swiftTooltip
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2279718198
                              ),
                              model: {
                                value: _vm.swiftTooltip,
                                callback: function ($$v) {
                                  _vm.swiftTooltip = $$v
                                },
                                expression: "swiftTooltip",
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("swift_tooltip_content"))),
                              ]),
                            ]
                          )
                        : _c(
                            VTooltip,
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "div",
                                        _vm._g(
                                          _vm._b({}, "div", attrs, false),
                                          on
                                        ),
                                        [
                                          _c("font-awesome-icon", {
                                            staticClass: "swift-tooltip-icon",
                                            attrs: {
                                              icon: "fa-solid fa-circle-info",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("swift_tooltip_content"))),
                              ]),
                            ]
                          ),
                    ],
                    1
                  ),
                  _c(VTextField, {
                    staticClass: "field my-input-style",
                    attrs: {
                      color: "primary",
                      outlined: "",
                      dense: "",
                      rules: [_vm.required],
                      "data-test": "complete_profile:bank_swift",
                    },
                    model: {
                      value: _vm.BankAccount.SWIFT,
                      callback: function ($$v) {
                        _vm.$set(_vm.BankAccount, "SWIFT", $$v)
                      },
                      expression: "BankAccount.SWIFT",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCol,
                {
                  staticClass: "py-0",
                  attrs: { cols: "12", xl: "6", lg: "6", md: "6", sm: "6" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "aba-tooltip-box" },
                    [
                      _c("p", { staticClass: "ml-2 mb-0" }, [
                        _vm._v(" " + _vm._s(_vm.$t("aba")) + " "),
                      ]),
                      _vm.gs.isMobile()
                        ? _c(
                            VTooltip,
                            {
                              attrs: { "max-width": "300", left: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "div",
                                          _vm._g(
                                            _vm._b({}, "div", attrs, false),
                                            on
                                          ),
                                          [
                                            _c("font-awesome-icon", {
                                              staticClass: "aba-tooltip-icon",
                                              attrs: {
                                                icon: "fa-solid fa-circle-info",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.abaTooltip =
                                                    !_vm.abaTooltip
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2311234091
                              ),
                              model: {
                                value: _vm.abaTooltip,
                                callback: function ($$v) {
                                  _vm.abaTooltip = $$v
                                },
                                expression: "abaTooltip",
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("aba_tooltip_content"))),
                              ]),
                            ]
                          )
                        : _c(
                            VTooltip,
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "div",
                                        _vm._g(
                                          _vm._b({}, "div", attrs, false),
                                          on
                                        ),
                                        [
                                          _c("font-awesome-icon", {
                                            staticClass: "aba-tooltip-icon",
                                            attrs: {
                                              icon: "fa-solid fa-circle-info",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("aba_tooltip_content"))),
                              ]),
                            ]
                          ),
                    ],
                    1
                  ),
                  _c(VTextField, {
                    staticClass: "field",
                    attrs: {
                      color: "primary",
                      outlined: "",
                      dense: "",
                      rules: [_vm.required],
                      "data-test": "complete_profile:bank_aba",
                    },
                    model: {
                      value: _vm.BankAccount.ABA,
                      callback: function ($$v) {
                        _vm.$set(_vm.BankAccount, "ABA", $$v)
                      },
                      expression: "BankAccount.ABA",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCol,
                {
                  staticClass: "py-0",
                  attrs: { cols: "12", xl: "12", lg: "12", md: "12", sm: "12" },
                },
                [
                  _c("p", { staticClass: "ml-2 mb-2" }, [
                    _vm._v(" " + _vm._s(_vm.$t("other_informations")) + " "),
                  ]),
                  _c(VTextarea, {
                    staticClass: "field my-input-style",
                    attrs: {
                      color: "primary",
                      outlined: "",
                      dense: "",
                      "data-test": "complete_profile:bank_other_informations",
                    },
                    model: {
                      value: _vm.BankAccount.OtherInformation,
                      callback: function ($$v) {
                        _vm.$set(_vm.BankAccount, "OtherInformation", $$v)
                      },
                      expression: "BankAccount.OtherInformation",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }